import React, { FC, ReactElement } from 'react';
import BackgroundImage from 'gatsby-background-image';
import Buttons from '../../components/buttons/feature-buttons';
import AnchorLinkButton from '../buttons/anchor-link-button';
import ArrowAnchorButton from '../buttons/arrow-anchor-button';
import { Route } from '../../models/site-links';
import './hero-banner.css';
import './hero.css';

interface HeroProps {
  heroImage: any;
  banner?: ReactElement;
  heading?: string;
  subHeading?: string;
  headingText?: string;
  link?: Route;
  linkText?: string;
  secondLink?: Route;
  secondLinkText?: string;
  anchorLink?: string;
  anchor?: string;
  buttonId: string;
}

const PhotoHero: FC<HeroProps> = (props) => {
  return (
    <BackgroundImage className="w-full" fluid={props.heroImage}>
      <section id="header" className="pt-24 w-full">
        {props.banner}
        <div className="hero2-container">
          <div className="hero2-textblock">
            <h1 className="text-white font-heading mb-4 font-bold leading-none hero2-tagline">
              {props.heading}
            </h1>
            <h4 className="text-white hero2-sub-heading">{props.subHeading}</h4>
            <p className="text-white text-xl font-hairline max-w-2xl leading-relaxed hero2-headingText">
              {props.headingText}
            </p>
            {props.link && (
              <div className="hero2-buttons">
                <Buttons
                  buttonId={props.buttonId}
                  link={props.link}
                  linkText={props.linkText}
                  secondLink={props.secondLink}
                  secondLinkText={props.secondLinkText}
                />
              </div>
            )}
            {props.anchorLink && (
              <div className="hero2-buttons">
                <AnchorLinkButton heroButton link={props.anchorLink} linkText={props.linkText} />
              </div>
            )}
            {props.anchor && (
              <div className="a-anchr-butn-cont">
                <ArrowAnchorButton anchor={props.anchor} linkText={props.linkText} />
              </div>
            )}
          </div>
        </div>
      </section>
    </BackgroundImage>
  );
};

export default PhotoHero;

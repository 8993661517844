import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import SEO from '../components/seo';
import PhotoHero from '../components/sections/photo-hero';
import Button from '../components/buttons/button';
import { ContactInformation } from '../components/sections/about/ContactInformation';
import './our-story.css';
import { Route } from '../models/site-links';

const OurStory = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "hero-images/team-hero-horizontal.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const contacts = [
    {
      name: 'North American Sales',
      phone: '1-877-778-9798',
      email: 'sales@patronscan.com',
    },
    {
      name: 'United Kingdom Sales',
      phone: '+44 (0)1702 549100',
      email: 'sales@patronscan.uk',
    },
    {
      name: 'Australia Sales',
      phone: '+61 28 5566555',
      email: 'sales@patronscan.com.au',
    },
    {
      name: 'Media Inquiries',
      phone: '1-916-817-4444',
      email: 'marko@rivercitycomm.com',
    },
    {
      name: 'Support',
      phone: '1-877-778-9798',
      email: 'support@patronscan.com',
    },
  ];

  const jobs = [
    {
      id: 1,
      title: 'Full Stack Developer',
      text: "We are looking for someone who is self-driven, quick, and willing to learn. You take pride in your work, excel under pressure, and aren't afraid to make mistakes. The product we are developing will be a big upgrade to our existing platform, and will include many technical aspects. We deal with everything from embedded hardware & electronics, backend infrastructure, the user interfaces, databases, our support team, SMS, and a lot more. There is a huge range of technologies included that we're excited to be working with and innovating. Core code is written in Rust, C++ and TypeScript. There are portions that are in C#, and others",
    },
    {
      id: 2,
      title: 'Technical Support',
      text: 'We are looking to hire a self motivated technical support specialist. This is a great role to start with our organization and have first hand experience with our products and our customers. Our ideal candidate must have strong general technical skills. They must be able to open up a computer and replace hardware components. Ability to assemble our proprietary kiosks and wall mounts. Be a self-starter who demonstrates a high degree of initiative, drive and energy. Have excellent interpersonal, networking and time management skills. Be motivated to excel in a dynamic and changing technology environment',
    },
  ];
  return (
    <div>
      <SEO title="Our Story - Patronscan" />
      <section id="our-story">
        <PhotoHero
          heroImage={data.file.childImageSharp.fluid}
          heading="Who We Are"
          subHeading="Our mission is to create safe environments and trustworthy relationships through identity verification."
          buttonId="OurStoryHero"
        />
        <div className="max-w-container mx-auto py-16 px-4">
          <h2 className="text-blue mb-8 text-5xl font-heading font-hairline">Our Story</h2>
          <p className="my-8 text-grey-darker">
            Our history is a humble one, we were founded in 2005 by the partnership of two friends
            who were also software developers. Both worked their evenings and weekends and
            bootstrapped their way to create the first Patronscan product. Their initial focus was
            to use ID scanners to create safe environments for bar goers and bar staff while
            ensuring that the product was the most privacy stringent in the market. Through knocking
            on many doors, our first customer signed up in British Columbia, Canada in 2006. Since
            then, Patronscan has become the premiere ID scanner available anywhere in Canada, the
            United States of America, Australia, and the UK, with the largest ID scanning network in
            North America and Australia. Today, we are gracious to have customers in over 200 cities
            all over the world and strategic partnerships to that help us deliver safe environments
            and trustworthy relationships.
          </p>
          <p className="my-8 text-grey-darker">
            At Patronscan we believe that a multidisciplinary team with the combination of skills of
            each member is the key to generating optimization and the maximum value within the
            services we provide.
          </p>
          <p className="my-8 text-grey-darker">
            Our team is dedicated to software architecture, software development, graphic design,
            project management, technical &amp; functional support, marketing, sales, service
            delivery and administration. We deliver excellence in the provision of ID Scanning
            technology - hardware and software.
          </p>
        </div>
        <ContactInformation contacts={contacts} />
        <section className="py-12 px-4 mt-20 join-our-team-container">
          <div className="max-w-container mx-auto mb-12">
            <h2 className="text-blue mt-8 mb-8 text-5xl font-heading font-hairline">
              Join Our Team
            </h2>
            <p className="my-8 text-grey-darker">
              We build a wide variety of hardware and software using the latest technologies. We
              have world class customer service. We are a high performance team, constantly looking
              for the next way to improve our amazing service. Every member of our team is critical
              to our success, and the success of our customers. We also know how to have fun.
            </p>
            <p className="my-8 text-grey-darker">
              With a company full of high energy, forward thinking people, you know fun is going to
              be a large part of our day. We want you to bring your drive and knowledge, and help us
              enjoy our work environment.
            </p>
          </div>
          <div className="flex flex-row max-w-container flex-wrap items-stretch mx-auto mb-8">
            {jobs.map((job: any) => {
              return (
                <div key={job.id} className="my-4 md:my-0 md:p-4 md:w-1/2 lg:w-1/3">
                  <div className="bg-white flex flex-col justify-between p-4 lg:p-8 shadow-lg w-full md:max-w-full">
                    <div>
                      <h2 className="text-2xl font-heading font-hairline text-grey-darker">
                        {job.title}
                      </h2>
                      <p className="my-8 text-grey-darker">{job.text}</p>
                    </div>
                    <Button
                      buttonId="OurStory:WorkWithUs"
                      blue
                      internal
                      link={Route.WorkWithUs}
                      text="Apply Now"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      </section>
    </div>
  );
};

export default OurStory;

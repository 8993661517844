import React, { FC } from 'react';

interface ContactInformationProps {
    contacts: ContactInformation[];
}

export const ContactInformation: FC<ContactInformationProps> = (props) => {
    return (
        <section className="max-w-container mx-auto py-16">
            <div className="flex flex-row flex-wrap text-center">
                <h2 className="text-5xl font-hairline text-blue font-heading mb-4">Contacts</h2>
            </div>
            <div className="flex flex-row flex-wrap items-center justify-center">
                {props.contacts.map((contact) => (
                    <div className="my-4 md:my-0 md:p-4 md:w-1/2 lg:w-1/3">
                        <div className="bg-white flex flex-col justify-between p-4 lg:p-8 shadow-lg w-full md:max-w-full text-center">
                            <h3 className="text-2xl text-blue text-center font-heading mb-2">{contact.name}</h3>
                            <p className="text-lg my-2 text-grey-darker">{contact.phone}</p>
                            <p className="text-lg my-2 text-grey-darker">{contact.email}</p>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    )
}